import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

function BenchmarkPage() {
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        const savedChartData = localStorage.getItem('chartData');
        if (savedChartData) {
            setChartData(JSON.parse(savedChartData));
        }
    }, []);

    const generateBenchmarkData = (originalData) => {
        const updatedDatasets = originalData.datasets.map((dataset) => {
            const updatedData = dataset.data.map(value => value * 0.85); // Reduce by 15%
            return {
                ...dataset,
                data: updatedData,
                label: dataset.label + ' (Decreased by 15%)',
            };
        });

        return {
            labels: originalData.labels,
            datasets: [
                ...originalData.datasets,
                ...updatedDatasets,
            ],
        };
    };

    return (
        <div style={{ padding: '20px' }}>
            <h2>Benchmark Data (Original & 15% Decrease)</h2>

            {/* Chart Data Loading */}
            {chartData ? (
                <div>
                    {/* First Row - Main Chart */}
                    <div style={{ margin: 'auto', textAlign: 'center', maxWidth: '50%' }}>
                        <h3>Main:</h3>
                        <Bar
                            data={generateBenchmarkData(chartData)}
                            options={{
                                responsive: true,
                                maintainAspectRatio: true,
                            }}
                        />
                    </div>

                    {/* Second Row - Column Charts */}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        gap: '20px',
                        marginTop: '20px',
                    }}>
                        {/* Column 1 */}
                        <div style={{ flex: 1 }}>
                            <h3>Column 1:</h3>
                            <Bar
                                data={{
                                    labels: chartData.labels,
                                    datasets: [
                                        chartData.datasets[0],
                                        {
                                            ...chartData.datasets[0],
                                            data: chartData.datasets[0].data.map(value => value * 0.85),
                                            label: 'Column 1 (Decreased by 15%)',
                                        },
                                    ],
                                }}
                                options={{ responsive: true }}
                            />
                        </div>

                        {/* Column 2 */}
                        <div style={{ flex: 1 }}>
                            <h3>Column 2:</h3>
                            <Bar
                                data={{
                                    labels: chartData.labels,
                                    datasets: [
                                        chartData.datasets[1],
                                        {
                                            ...chartData.datasets[1],
                                            data: chartData.datasets[1].data.map(value => value * 0.85),
                                            label: 'Column 2 (Decreased by 15%)',
                                        },
                                    ],
                                }}
                                options={{ responsive: true }}
                            />
                        </div>

                        {/* Column 3 */}
                        <div style={{ flex: 1 }}>
                            <h3>Column 3:</h3>
                            <Bar
                                data={{
                                    labels: chartData.labels,
                                    datasets: [
                                        chartData.datasets[2],
                                        {
                                            ...chartData.datasets[2],
                                            data: chartData.datasets[2].data.map(value => value * 0.85),
                                            label: 'Column 3 (Decreased by 15%)',
                                        },
                                    ],
                                }}
                                options={{ responsive: true }}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <p>Loading Benchmark Data...</p>
            )}
        </div>
    );
}

export default BenchmarkPage;