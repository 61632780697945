// DashboardPage.js
import React from 'react';
import TableauDashboard from './TableauDashboard';

const DashboardPage = () => {
  return (
    <div className="dashboard-page">
      <TableauDashboard />
    </div>
  );
};

export default DashboardPage;