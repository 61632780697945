import React from 'react';

const NotificationPage = () => {
    // Your distribution list email address
    const distributionListEmail = "dl-magnolia-analytics-portal-customer@magnolia-analytics.com";
    
    // Define the subject and body of the email, if needed
    const emailSubject = "Subject for Email";
    const emailBody = "Hello, this is the body of the email.";

    // Construct the mailto link dynamically
    const mailtoLink = `mailto:${distributionListEmail}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;

    return (
        <div>
            <h1>Notification Page</h1>
            <p>Click the button below to send an email to the distribution list:</p>
            <button onClick={() => window.location.href = mailtoLink}>
                Send Email
            </button>
        </div>
    );
};

export default NotificationPage;