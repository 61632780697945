import React, { useState } from 'react';
import { BlobServiceClient } from '@azure/storage-blob';

const FileUploadPage = () => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const storageAccountName = 'marwadata';
  const containerName = 'map';
  const sasToken = 'sp=racw&st=2024-11-06T15:44:35Z&se=2024-12-01T23:44:35Z&spr=https&sv=2022-11-02&sr=c&sig=RtRm7UAVrucQwwLUQmQUQYsV%2BshdcQ4JUR%2FbepzpD7s%3D'; // Ensure SAS token is valid

  // Initialize BlobServiceClient
  const containerClient = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net?${sasToken}`
  ).getContainerClient(containerName);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const uploadFile = async () => {
    if (!file) return;
    setUploading(true);
    setUploadError(null);
    setUploadSuccess(false);

    try {
      const blobClient = containerClient.getBlockBlobClient(file.name);

      // Upload the file to Azure Blob Storage
      const uploadBlobResponse = await blobClient.uploadData(file, {
        blobHTTPHeaders: { blobContentType: file.type },
      });

      console.log('Upload successful', uploadBlobResponse);
      setUploadSuccess(true);
    } catch (error) {
      console.error('Error uploading file', error);
      setUploadError(error.message || error.toString());
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="upload-wrapper">
      <div className="left-upload">
        <p1>Hello</p1>
        <h2>This is under construction</h2>
      </div>
      <div className="upload-container">
        <input type="file" onChange={handleFileChange} />
        <button onClick={uploadFile} disabled={uploading}>Upload</button>

        {uploading && <p>Uploading...</p>}
        {uploadSuccess && <p>File uploaded successfully!</p>}
        {uploadError && <p>Error: {uploadError}</p>}
      </div>
      <div className="right-upload">
        <p1>Hello</p1>
        <h2>This is under construction</h2>
      </div>
    </div>
  );
};

export default FileUploadPage;