import logo from './Images/MA-Logo-RGB.png';
import './CSS/App.css';
import LoginButton from './Pages/LoginButton';
import HomePage from './Pages/HomePage';
import UploadPage from './Pages/UploadPage';
import DashboardPage from './Pages/DashboardPage';
import AdminPage from './Pages/AdminPage';
import FileUploadPage from './Pages/FileUploadPage';
import ProfilePage from './Pages/ProfilePage'; // Import the ProfilePage component
import NotificationPage from './Pages/NotificationPage';
import SupportPage from './Pages/SupportPage';
import BenchmarkPage from './Pages/BenchmarkPage';
import FeedbackPage from './Pages/FeedbackPage';
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { PublicClientApplication } from '@azure/msal-browser';
import { HashRouter as Router, Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom'; 
import authConfig from './authConfig';

const msalInstance = new PublicClientApplication(authConfig);
const timeoutDuration = 10 * 60 * 1000; // 10 minutes in milliseconds

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(null); 
    const [userInfo, setUserInfo] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isWelcomeMenuOpen, setIsWelcomeMenuOpen] = useState(false);  // Track state of welcome menu
    const [tableauToken, setTableauToken] = useState(null);
    const [isDarkMode, setIsDarkMode] = useState(localStorage.getItem('theme') === 'dark'); // Load theme from localStorage

    const navigate = useNavigate();
    const location = useLocation(); 

    useEffect(() => {
        const storedUserInfo = localStorage.getItem('userInfo');
        if (storedUserInfo) {
            setIsLoggedIn(true);
            setUserInfo(JSON.parse(storedUserInfo));
        } else {
            setIsLoggedIn(false); 
        }
    }, []); 

    const handleLogout = useCallback(() => {
        setIsLoggedIn(false);
        setUserInfo(null);
        setTableauToken(null);
        localStorage.removeItem('userInfo');
        console.log("Session timed out. Please log in again.");
        navigate('/signin'); 
    }, [navigate]);

    useEffect(() => {
        let timeout;
        if (isLoggedIn) {
            timeout = setTimeout(() => {
                handleLogout();
            }, timeoutDuration);
        }

        return () => {
            clearTimeout(timeout);
        };
    }, [isLoggedIn, handleLogout]);

    const acquireTableauTokenSilent = async () => {
        try {
            const msalToken = await msalInstance.acquireTokenSilent({
                scopes: ['User.Read'],
            });

            const response = await axios.post('https://10ay.online.tableau.com/api/3.10/auth/signin', {
                credentials: {
                    token: msalToken.accessToken 
                }
            });

            return response.data.token;
        } catch (error) {
            console.error('Error acquiring Tableau token', error);
            return null;
        }
    };

    const isAdmin = userInfo?.roles?.includes('Admin'); 

    const ProtectedRoute = ({ children, isAdminRequired }) => {
        if (isLoggedIn === null) {
            return null;
        }
        if (!isLoggedIn) {
            return <Navigate to="/signin" />;
        }
        if (isAdminRequired && !isAdmin) {
            return <Navigate to="/" />;
        }
        return children;
    };

    const toggleTheme = () => {
        const newMode = isDarkMode ? 'light' : 'dark';
        setIsDarkMode(!isDarkMode);
        localStorage.setItem('theme', newMode);
    };

    if (isLoggedIn === null) {
        return <div>Loading...</div>;
    }

    const handleWelcomeButtonClick = (e) => {
        e.preventDefault();
        setIsWelcomeMenuOpen(prev => !prev);  // Toggle the welcome menu
    };

    return (
        <div className={`App ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                {!isLoggedIn && location.pathname !== '/signin' && (
                    <LoginButton setIsLoggedIn={setIsLoggedIn} setUserInfo={setUserInfo} setTableauToken={setTableauToken} />
                )}
                {isLoggedIn && (
                    <div className="header-buttons">
                        <button
                            className="welcome-message"
                            onClick={handleWelcomeButtonClick}
                        >
                            Welcome, {userInfo?.name}!
                        </button>
                        {/* Welcome Menu Dropdown */}
                        {isWelcomeMenuOpen && (
                            <div className="welcome-menu">
                                <ul>
                                    <li>
                                        <button className="button" onClick={() => navigate('/profile')}>
                                            View Profile
                                        </button>
                                    </li>
                                    <li>
                                        <button className="button" onClick={handleLogout}>
                                            Sign Out
                                        </button>
                                    </li>
                                    <li>
                                        <button className="button" onClick={toggleTheme}>
                                            {isDarkMode ? 'Switch to Light Mode' : 'Switch to Dark Mode'}
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        )}
                        <button
                            type="button"
                            className="menu-toggle"
                            onClick={(e) => {
                                e.preventDefault();
                                setIsMenuOpen(prev => !prev);
                            }}
                        >
                            {isMenuOpen ? 'Close Menu' : 'Open Menu'}
                        </button>
                    </div>
                )}
            </header>

            {isLoggedIn && isMenuOpen && (
                <div className="expandable-menu">
                    <ul>
                        <li>
                            <button className="button" onClick={() => navigate('/')}>Home Page</button>
                        </li>
                        <li>
                            <button className="button" onClick={() => navigate('/upload')}>Dashboard Page</button>
                        </li>
                        <li>
                            <button className="button" onClick={() => navigate('/fileupload')}>Upload Page</button>
                        </li>
                        <li>
                            <button className="button" onClick={() => navigate('/notification')}>Notification Page</button>
                        </li>
                        <li>
                            <button className="button" onClick={() => navigate('/support')}>Support Page</button>
                        </li>
                        <li>
                            <button className="button" onClick={() => navigate('/benchmark')}>Benchmark Page</button>
                        </li>
                        <li>
                            <button className="button" onClick={() => navigate('/feedback')}>Feedback Page</button>
                        </li>
                        {isAdmin && (
                            <li>
                                <button className="button" onClick={() => navigate('/admin')}>Admin Page</button>
                            </li>
                        )}
                        <li>
                            <button className="button" onClick={async () => {
                                if (!tableauToken) {
                                    const token = await acquireTableauTokenSilent();
                                    setTableauToken(token);
                                }
                                navigate('/dashboard');
                            }}>
                                REDACTED
                            </button>
                        </li>
                    </ul>
                </div>
            )}

            <main>
                <Routes>
                    <Route path="/signin" element={isLoggedIn ? <Navigate to="/" /> : <LoginButton setIsLoggedIn={setIsLoggedIn} setUserInfo={setUserInfo} setTableauToken={setTableauToken} />} />
                    <Route path="/" element={isLoggedIn ? <HomePage /> : <Navigate to="/signin" />} />
                    <Route path="/upload" element={<ProtectedRoute><UploadPage userInfo={userInfo} /></ProtectedRoute>} />
                    <Route path="/fileupload" element={<ProtectedRoute><FileUploadPage /></ProtectedRoute>} />
                    <Route path="/dashboard" element={<ProtectedRoute><DashboardPage token={tableauToken} /></ProtectedRoute>} />
                    <Route path="/admin" element={<ProtectedRoute isAdminRequired><AdminPage /></ProtectedRoute>} />
                    <Route path="/profile" element={<ProtectedRoute><ProfilePage userInfo={userInfo} /></ProtectedRoute>} />
                    <Route path="/notification" element={<ProtectedRoute><NotificationPage /></ProtectedRoute>} />
                    <Route path="/support" element={<ProtectedRoute><SupportPage /></ProtectedRoute>} />
                    <Route path="/benchmark" element={<ProtectedRoute><BenchmarkPage /></ProtectedRoute>} />
                    <Route path="/feedback" element={<ProtectedRoute><FeedbackPage /></ProtectedRoute>} />
                </Routes>
            </main>
        </div>
    );
}

const AppWrapper = () => (
    <Router>
        <App />
    </Router>
);

export default AppWrapper;