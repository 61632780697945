import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import Papa from 'papaparse'; // Library to parse CSV
import jsPDF from 'jspdf'; // Import jsPDF

// Register all necessary Chart.js components
Chart.register(...registerables);

function ComponentA({ userInfo }) {
    const [csvContent, setCsvContent] = useState('');
    const [chartData, setChartData] = useState(null);
    const [showRawContent, setShowRawContent] = useState(false);
    const [uploadStatus, setUploadStatus] = useState('Upload CSV File');

    useEffect(() => {
        // Load saved data from localStorage when the component mounts
        const savedCsv = localStorage.getItem('csvContent');
        const savedChartData = localStorage.getItem('chartData');
        if (savedCsv) {
            setCsvContent(savedCsv);
            if (savedChartData) {
                setChartData(JSON.parse(savedChartData));
            }
        }
    }, []);

    // Save data to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem('csvContent', csvContent);
        if (chartData) {
            localStorage.setItem('chartData', JSON.stringify(chartData));
        } else {
            localStorage.removeItem('chartData'); // Remove if no chart data
        }
    }, [csvContent, chartData]);

    const deIdentifyData = (data) => {
        return data.map(row => {
            const { Name, Email, Nurse, ...rest } = row; // Remove sensitive fields
            return { ...rest }; // Return the rest of the data
        });
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length > 0) {
            const file = files[0];
            if (file.type === 'text/csv') {
                const reader = new FileReader();
                reader.onload = (event) => {
                    const text = event.target.result;
                    setCsvContent(text);
                    setUploadStatus('Upload Successful!');
                    generateChartData(text);
                };
                reader.readAsText(file);
            } else {
                alert('Please drop a valid CSV file.');
            }
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const generateChartData = (csv) => {
        Papa.parse(csv, {
            header: true,
            complete: (results) => {
                const deIdentifiedData = deIdentifyData(results.data);

                // Common labels (using the first column in the data)
                const labels = deIdentifiedData.map(row => row[Object.keys(row)[0]]);

                // Bar Chart Data 1 (First Chart)
                const data1 = deIdentifiedData.map(row => parseFloat(row[Object.keys(row)[1]]));
                // Bar Chart Data 2 (Second Chart)
                const data2 = deIdentifiedData.map(row => parseFloat(row[Object.keys(row)[2]]));
                // Bar Chart Data 3 (Third Chart)
                const data3 = deIdentifiedData.map(row => parseFloat(row[Object.keys(row)[3]]));

                setChartData({
                    labels,
                    datasets: [
                        {
                            label: 'Chart 1 Data',
                            data: data1,
                            backgroundColor: 'rgba(75, 192, 192, 0.6)',
                            borderColor: 'rgba(75, 192, 192, 1)',
                            borderWidth: 1,
                        },
                        {
                            label: 'Chart 2 Data',
                            data: data2,
                            backgroundColor: 'rgba(153, 102, 255, 0.6)',
                            borderColor: 'rgba(153, 102, 255, 1)',
                            borderWidth: 1,
                        },
                        {
                            label: 'Chart 3 Data',
                            data: data3,
                            backgroundColor: 'rgba(255, 159, 64, 0.6)',
                            borderColor: 'rgba(255, 159, 64, 1)',
                            borderWidth: 1,
                        },
                    ],
                });
            },
        });
    };

    const toggleRawContent = () => {
        setShowRawContent(prev => !prev);
    };

    const resetData = () => {
        setCsvContent('');
        setChartData(null);
        setShowRawContent(false);
        setUploadStatus('Upload CSV File');
        localStorage.removeItem('csvContent'); // Clear from localStorage
        localStorage.removeItem('chartData'); // Clear from localStorage
    };

    // Function to generate and download PDF
    const downloadPDF = () => {
        if (!chartData) {
            alert('No charts available to download.');
            return;
        }

        const doc = new jsPDF();
        doc.text('Generated Charts', 10, 10);

        // Chart 1
        doc.addPage();
        doc.text('Main Chart', 10, 10);
        doc.addImage(document.getElementsByTagName('canvas')[0].toDataURL('image/png'), 'PNG', 10, 20, 180, 100);

        // Chart 2
        doc.addPage();
        doc.text('Chart 2', 10, 10);
        doc.addImage(document.getElementsByTagName('canvas')[1].toDataURL('image/png'), 'PNG', 10, 20, 180, 100);

        // Chart 3
        doc.addPage();
        doc.text('Chart 3', 10, 10);
        doc.addImage(document.getElementsByTagName('canvas')[2].toDataURL('image/png'), 'PNG', 10, 20, 180, 100);

        doc.save('charts.pdf');
    };

    // Check if the user is an Admin
    const isAdmin = userInfo?.roles?.includes('Admin'); // Assuming `roles` is an array

    return (
        <div>
            <h2>{uploadStatus}</h2>
            {!csvContent && (
                <div
                    style={{
                        width: 'calc(100% - 4vw)',
                        height: 'calc(20vh)',
                        padding: '0vw',
                        margin: '5vh auto',
                        textAlign: 'center',
                        lineHeight: 'calc(15vh)',
                        color: '#aaa',
                        border: '2px dashed #007bff',
                        borderRadius: '10px',
                        backgroundColor: '#0842A6',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxSizing: 'border-box',
                    }}
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                >
                    Drag and drop a CSV file here
                </div>
            )}
            {csvContent && (
                <div>
                    {/* Conditionally render button only for Admin users */}
                    {isAdmin && (
                        <button className="App-button" onClick={toggleRawContent}>
                            {showRawContent ? 'Hide Raw CSV Content' : 'Show Raw CSV Content'}
                        </button>
                    )}

                    {showRawContent && (
                        <div>
                            <h3>Raw CSV Content:</h3>
                            <pre>{csvContent}</pre>
                        </div>
                    )}

                    {/* Chart Container with Flexbox */}
                    {chartData && (
                        <div>
                            {/* First Row - Chart 1 */}
                            <div style={{ margin: 'auto', textAlign: 'center', maxWidth: '50%' }}>
                                <h3>Main:</h3>
                                <Bar
                                    data={chartData}
                                    options={{
                                        responsive: true,
                                        maintainAspectRatio: true, // Let it resize based on container size
                                    }}
                                />
                            </div>

                            {/* Second Row - Chart 2, Chart 3, and Chart 4 */}
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '20px' }}>
                                {/* Chart 4 */}
                                <div style={{ flex: 1 }}>
                                    <h3>Column 1:</h3>
                                    <Bar
                                        data={{
                                            labels: chartData.labels,
                                            datasets: [chartData.datasets[0]] // Using the first dataset for Chart 4
                                        }}
                                        options={{ responsive: true }}
                                    />
                                </div>

                                {/* Chart 2 */}
                                <div style={{ flex: 1 }}>
                                    <h3>Column 2:</h3>
                                    <Bar
                                        data={{
                                            labels: chartData.labels,
                                            datasets: [chartData.datasets[1]] // Using the second dataset for Chart 2
                                        }}
                                        options={{ responsive: true }}
                                    />
                                </div>

                                {/* Chart 3 */}
                                <div style={{ flex: 1 }}>
                                    <h3>Column 3:</h3>
                                    <Bar
                                        data={{
                                            labels: chartData.labels,
                                            datasets: [chartData.datasets[2]] // Using the third dataset for Chart 3
                                        }}
                                        options={{ responsive: true }}
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Download PDF Button */}
                    <button className="App-button" onClick={downloadPDF} style={{ marginTop: '20px' }}>
                        Download PDF
                    </button>

                    <button className="App-button" onClick={resetData} style={{ marginTop: '20px' }}>
                        Reset
                    </button>
                </div>
            )}
        </div>
    );
}

export default ComponentA;