// ProfilePage.js
import React from 'react';

const ProfilePage = ({ userInfo }) => {
    return (
        <div className="profile-page">
            <h2>Your Profile</h2>
            <div className="profile-info">
                <p><strong>Name:</strong> {userInfo?.name}</p>
                <p><strong>Email:</strong> {userInfo?.email}</p>
                <p><strong>Roles:</strong> {userInfo?.roles?.join(', ')}</p>
                {/* Add more user info fields here as needed */}
            </div>
        </div>
    );
};

export default ProfilePage;