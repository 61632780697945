import React, { useState } from 'react';

const HomePage = () => {
  // State to control visibility of preview modal
  const [showPreview, setShowPreview] = useState(false);
  const [previewContent, setPreviewContent] = useState('');  // Store content for preview

  // Function to handle button click and show the preview
  const handlePreview = (content) => {
    setPreviewContent(content); // Set the content for preview
    setShowPreview(true); // Show the preview window
  };

  // Function to handle closing the preview window
  const closePreview = () => {
    setShowPreview(false);
  };

  return (
    <div className="home-page">
      <h1>Welcome</h1>
      <p>Thank you for choosing Magnolia Analytics!</p>

      {/* First Section: Actionable Insights, Patient Impact, Economic Impact, Quality Improvement */}
      <div className="actionable-insights">
        <h2 className="insight-button" onClick={() => handlePreview('Here are actionable insights that can drive improvements in clinical practices and operational efficiency.')}>Actionable Insights</h2>
        <h2 className="insight-button" onClick={() => handlePreview('Explore the impact on patient outcomes, safety, and satisfaction.')}>Patient Impact</h2>
        <h2 className="insight-button" onClick={() => handlePreview('Discover the economic impact of adopting Magnolia Analytics technology in healthcare.')}>Economic Impact</h2>
        <h2 className="insight-button" onClick={() => handlePreview('Learn how quality improvement initiatives are powered by data and analytics.')}>Quality Improvement</h2>
      </div>

      {/* Second Section: Your Need and Features and Capabilities (side by side) */}
      <div className="content-container">
        {/* Left Side: Your Need */}
        <div className="your-need">
          <h3>Your Need</h3>
          <p>In today’s challenging financial environment, hospital leaders require hard proof that technology investments are paying off through improved patient safety and outcomes.</p>
          <p>Working with hospitals across the U.S., we developed the comprehensive Magnolia Analytics™ solution to arm clinical champions with the information they need to advocate for patients while supporting their hospital’s bottom-line.</p>
        </div>

        {/* Right Side: Features and Capabilities */}
        <div className="features-capabilities">
          <h3>Features And Capabilities</h3>
          <ul>
            <li>Designed to provide facility-level metrics on the impact of reducing blood culture contamination with Steripath® or Steripath® Micro™</li>
            <li>Key patient safety and outcome improvements</li>
            <li>Quantification of financial cost savings</li>
            <li>EHR and LIS enabled to automate data capture and reporting</li>
            <li>Compliance and protocol adherence reporting</li>
            <li>Additional capabilities that help hospital leaders to target and measure improvement initiatives that impact antibiotic stewardship and quality metrics</li>
          </ul>
        </div>
      </div>

      {/* Preview Window */}
      {showPreview && (
        <div className="preview-window">
          <div className="preview-content">
            <h2>Preview</h2>
            <p>{previewContent}</p>
            <button onClick={closePreview}>Close Preview</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomePage;