const authConfig = {
    auth: {
        clientId: '8d192213-c781-4efb-ac6a-03aac665adc9',
        authority: 'https://login.microsoftonline.com/dd1ca075-de8f-40c4-bebb-c90103860dba',
        redirectUri: window.location.origin,
    },
    cache: {
        cacheLocation: "localstorage",
        storeAuthStateInCookie: true,
    },
    system : {
        allowNativeBroker: true,
    },
};

export default authConfig;