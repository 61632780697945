// UploadPage.js
import React from 'react';
import ComponentA from './ComponentA'; // Assuming ComponentA handles CSV upload

const UploadPage = ({ userInfo }) => {
  return (
    <div className="upload-page">
      <ComponentA isVisible={true} userInfo={userInfo} />
    </div>
  );
};

export default UploadPage;