import React from 'react';

const SupportPage = () => {
    // Your distribution list email address
    const distributionListEmail = "itsupport@magnolia-medical.com";
    
    // Define the subject and body of the email, if needed
    const emailSubject = "Subject for Email";
    const emailBody = "Hello, this is the body of the email.";

    // Construct the mailto link dynamically
    const mailtoLink = `mailto:${distributionListEmail}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;

    return (
        <div>
            <h1>Support Page</h1>
            <p>Click the button below to send an email to our support team:</p>
            <button onClick={() => window.location.href = mailtoLink}>
                Send Email
            </button>
        </div>
    );
};

export default SupportPage;